import { Card, Button, Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { BsHeartFill } from "react-icons/bs"
import React, { useEffect, useState } from "react";
import { MdModeEditOutline, MdWatchLater } from "react-icons/md"
import { IoCloudDownloadSharp } from "react-icons/io5"
import WebService from '../../../Services/WebService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CompoAiGeneratedCard = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isFav, setIsFav] = useState(false);
    useEffect(() => {
        setIsFav(props.element?.favourite)
    }, [props.element])

    const handleFav = (event: any) => {
        let isFav = event.favourite
        isFav === 0 ? (isFav = 1) : (isFav = 0)
        const obj = {
            id: event.id,
            favourite: isFav
        }
        WebService.putAPI({
            action: `creative-image/favourite?`,
            body: obj,
        })
            .then((res: any) => {
                props.element.favourite = isFav;
                setIsFav(isFav)
            })
            .catch((error) => {
                return error;
            });

    }
    

    const handleDownload = (value: any) => {
        var qurey = new URLSearchParams(value).toString();
        WebService.getAPI({
            action: `creative-image/download?` + qurey,
            body: null,
        })
            .then((res: any) => {
                window.open(res, "_blank")
            })
            .catch((error) => {
                return error;
            });

    }

    const handleSchedule = (ele: any) => {
        localStorage.setItem("schedule_post_image", ele.image)
        navigate("/social-campaign/schedule/post");
    }

    return (
        <>
            <Card className="card-body ai-generated-card">
                <img alt="" src={props.element.image} className="template-img" />
                <div className="mt-3 d-flex justify-content-between ">
                    <div className="d-flex gap-2">
                        <Form.Group className=" " controlId="chek_1">
                            <Form.Check type="checkbox" label=" "
                                onChange={(e) => {
                                    props.parentCallback(props.element, e.target.checked, "CHECKBOX")
                                }}
                            />
                        </Form.Group>
                        <ToggleButtonGroup type="checkbox">
                            <ToggleButton id={props.element.id} value={props.element.image} className="btn-outline-light"
                                onChange={(event) => { handleFav(props.element) }
                                }
                            >
                                <BsHeartFill className={isFav ? "icon1" : "icon2"} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        <Button className="btn-outline-light" onClick={(event) => handleSchedule(props.element)}> <MdWatchLater className="icon" /></Button>
                        <Button className="btn-outline-light" onClick={() => { props.parentCallback(props.element, "", "EDIT") }}> <MdModeEditOutline className="icon" /></Button>
                        <Button className="btn-wmx px-3 font-500" onClick={(value) => { handleDownload(props.element) }}><IoCloudDownloadSharp className="icon" />{t('Common.Download')}</Button>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default CompoAiGeneratedCard;