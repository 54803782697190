import { useEffect, useState } from "react";
import "./DeleteModal.css";
import WebService from "../../../Services/WebService";
import AccountWebService from "../../../Services/AccountWebService";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropData {
  show: boolean;
  onDelete: any;
  action?: any;
  message?: any;
  heading?: any;
  successbutton?: any;
  cancelbutton?: any;
  size?: any;
  api?: any;
  body?: any;
  sucessButtonClick?: boolean;
  sucessHandler?: any;
  currentPage?: any;
  totalCount?: any;
}

const DeleteModal = (props: PropData) => {
  const { t } = useTranslation();
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const closeClick = () => {
    setShow(false);
    props.onDelete(false);
  };

  const successClick = () => {
    if (props.sucessButtonClick) {
      props.sucessHandler();
    } else {
      WebService.addLoader("deleteBtnModal");
      if (props.api === "POST") {
        WebService.postAPI({
          action: props.action,
          body: props.body,
        })
          .then((res: any) => {
            toast(res.message ?? "Deleted successfully!", {
              position: "top-right",
              type: "success",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
            setShow(false);
            checkPageObjCount();
            props.show = false;
            WebService.removeLoader("deleteBtnModal");
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
            WebService.removeLoader("deleteBtnModal");
          });
      } else if (props.api === "PUT") {
        WebService.putAPI({
          action: props.action,
          body: props.body,
        })
          .then((res: any) => {
            toast(res.message ?? "Deleted successfully!", {
              position: "top-right",
              type: "success",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
            setShow(false);
            checkPageObjCount();
            props.show = false;
            WebService.removeLoader("deleteBtnModal");
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
            WebService.removeLoader("deleteBtnModal");
          });
      }
      else if (props.api === "ACCOUNT") {
        AccountWebService.deleteAPI({
          action: props.action,
          body: null,
        })
          .then((res: any) => {
            toast(res.message ?? "Deleted successfully!", {
              position: "top-right",
              type: "success",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
            setShow(false);
            checkPageObjCount();
            props.show = false;
            WebService.removeLoader("deleteBtnModal");
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
            WebService.removeLoader("deleteBtnModal");
          });
      } else {
        WebService.deleteAPI({
          action: props.action,
          body: null,
        })
          .then((res: any) => {
            toast(res.message ?? "Deleted successfully!", {
              position: "top-right",
              type: "success",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
            setShow(false);
            checkPageObjCount();
            props.show = false;
            WebService.removeLoader("deleteBtnModal");
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
            WebService.removeLoader("deleteBtnModal");
          });
      }
    }
  };
  const checkPageObjCount = () => {
    if (props?.currentPage && props?.totalCount) {
      if (props.currentPage === Math.ceil(props.totalCount / 10)) {
        if (props.currentPage * 10 - props.totalCount === 9) {
          props.onDelete(true, ((props.currentPage - 1) === 0 ? 1 : props.currentPage - 1));
        } else {
          props.onDelete(true, props.currentPage);
        }
      } else {
        props.onDelete(true, props.currentPage);
      }
    } else {
      props.onDelete(true, props.currentPage);
    }
  }

  function MyVerticallyCenteredModal(props: any) {
    return (
      <Modal
        {...props}
        size={props.size ?? "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={closeClick}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.heading ?? t("Common.Confirmation")}
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: props.message ?? '<p>Are you sure you want to delete?</p>' }}>
          </p>
        </Modal.Body> */}
        <Modal.Body>
          <p
            dangerouslySetInnerHTML={{
              __html: props.message ?? t("Common.Delete_Message"),
            }}
          ></p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-wmx-gray" onClick={closeClick}>
            {props.successbutton ?? t("Common.No")}
          </Button>
          <Button
            className="btn-wmx loader-btn"
            id="deleteBtnModal"
            onClick={successClick}
          >
            {props.cancelbutton ?? t("Common.Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={isShow}
        onHide={() => {
          setShow(false);
          props.onDelete(false);
        }}
        message={props.message}
        heading={props.heading}
        successbutton={props.successbutton}
        cancelbutton={props.cancelbutton}
        size={props.size}
      />
    </>
  );
};

export default DeleteModal;
