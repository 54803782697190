import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface PropData {
  st: any;
  message: any;
  position: any;
  id: string;
}

export const TooltipCustom = (props: PropData) => {
  const entering = (e: any) => {
    // e.children[0].style.borderTopColor = 'green';
    e.children[1].style.backgroundColor = '#2a53e9';
  };
  const tool = <Tooltip className="my-tooltip" id={props.id} style={{ position: "fixed" }}>
    {props.message}
  </Tooltip>
  return (
    <OverlayTrigger placement={props.position} overlay={tool} onEntering={entering}>
      {props.st}
    </OverlayTrigger>
  );
};
