interface PropData {
  customClass?: any
}

const TableLoader = (props: PropData) => {


  return (
    <>
      <div className={props.customClass ? props.customClass + " mb-0   table-wrap position-relative " : "  mb-0  table-wrap position-relative "} >
        <div className='component-loader'>
          <div className='loader'></div>
        </div>
      </div >
    </>
  );
};

export default TableLoader;
