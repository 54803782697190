import React from "react";
import "./setting-page.scss";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Breadcrumb,
} from "react-bootstrap";
// import AvtartGenral from "../../../../assets/images/general-icon.svg"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./setting-page.scss";

import TabGenral from "./General/TabGeneral";
import TabWoocommers from "./WooCommerce/TabWoocommerce";
// import TabApiCredentials from "./APICredentials/TabAPICredentials";
import TabWhiteListedAPI from "./WhiteListedIP's/TabWhiteListedAPI";
import WebService from "../../Services/WebService";
import TabApiCredentials from "./APICredentials/TabAPICredentials";
import { useTranslation } from "react-i18next";
const Setting = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [brandDetails, setBrandDetails] = useState("");
  useEffect(() => {
    if (searchParams.get("id") ?? "") {
      getBrandSettings(searchParams.get("id") ?? "");
    } else {
      getBrandSettings(localStorage.getItem("app_id"));
    }
    getWooCommerceData();
    getAPICredentials();
    getWhiteListedIPs(currentPage);
  }, []);
  const navigate = useNavigate();
  const [brandType, setBrandType] = useState("");
  const [wooCommerceKeysData, setWooCommerceKeysData] = useState("");
  const [apiCredentials, setAPICredentials] = useState("");
  const [whiteListedIPsList, setWhiteListedIPsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("DESC");

  const getBrandSettings = (id: any) => {
    return WebService.getAPI({
      action: `user-website/details?id=` + id,
      body: null,
    })
      .then((res: any) => {
        setBrandDetails(res.info);
        setBrandType(res.info.type);
      })
      .catch((error) => {
        return error;
      });
  };
  const getWooCommerceData = () => {
    return WebService.getAPI({
      action:
        `marketing-campaign-channel/colums?id=` +
        localStorage.getItem("app_id"),
      body: null,
    })
      .then((res: any) => {
        setWooCommerceKeysData(res.result);
      })
      .catch((error) => {
        return error;
      });
  };
  const getAPICredentials = () => {
    return WebService.getAPI({
      action: `zapier/get-keys?id=` + localStorage.getItem("app_id"),
      body: null,
    })
      .then((res: any) => {
        setAPICredentials(res.data);
      })
      .catch((error) => {
        return error;
      });
  };
  const getWhiteListedIPs = (page: any) => {
    setLoader(true);
    const obj = {
      id: localStorage.getItem("app_id") ?? "",
      sortBy: sortBy,
      sort_order: sortOrder,
      page: page.toString(),
    };
    var qurey = new URLSearchParams(obj).toString();
    return WebService.getAPI({
      action: `whitelist-ip/list?` + qurey,
      body: null,
    })
      .then((res: any) => {
        setWhiteListedIPsList(res.list);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };
  return (
    <>
      <div className="app-body setting-page">
        <section className="page-breadcums">
          <Container fluid>
            <div className="">
              <Row className="align-items-center">
                <Col md={6}>
                  <Breadcrumb>
                    <Breadcrumb.Item><Link to={"/campaign-tracker"}>{t('Common.Home')}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{t('Common.Settings')}</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
                <Col md={6} className="text-end"></Col>
              </Row>
            </div>
          </Container>
        </section>
        <Container>
          <Row className="justify-content-center mt-5">
            <Col lg={7}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="wmx-tab-style-1">
                  <Nav variant="pills" className=" justify-content-center border-0 p-0 bg-transparent">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t('Common.General')}</Nav.Link>
                    </Nav.Item>
                    {brandType === "WORDPRESS" ?
                      <Nav.Item>
                        <Nav.Link eventKey="second">{t('Brands.WooCommerce')}</Nav.Link>
                      </Nav.Item>
                      :
                      ""
                    }
                    <Nav.Item>
                      <Nav.Link eventKey="third">{t('Common.API_Credentials')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">{t('Common.Whitelisted_IP')}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div>
                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="first">
                      <TabGenral data={brandDetails} sendData={(data: any) => { getBrandSettings(data) }} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <TabWoocommers data={wooCommerceKeysData} video="1" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <TabApiCredentials
                        data={apiCredentials}
                        sendData={(data: any) => getAPICredentials()}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <TabWhiteListedAPI
                        data={whiteListedIPsList}

                        sendData={(data: any) => {
                          getWhiteListedIPs(data);
                        }}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Setting;
