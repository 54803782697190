import { useEffect, useState } from "react";

import {
  Button,
  Offcanvas,
  Row,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import WMTable from "../../Common/WMTable/WMTable";
import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import { useForm } from "react-hook-form";
import { Label } from "../../Common/Label/Label";
import { MdClose } from "react-icons/md";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TabWhiteListedAPI = (props: any) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headers, setHeaders] = useState([
    {
      display_name: t('Common.Name'),
      is_sorting: false,
      colum_name: "name",
      is_default_sort: false,
      type: "STRING",
      sort_by: "",
    },
    {
      display_name: t('Common.IP'),
      is_sorting: false,
      colum_name: "ip",
      is_default_sort: true,
      type: "STRING",
      sort_by: "",
      class: "imgIcon",
    },
    {
      display_name: t('Common.Action'),
      is_sorting: false,
      colum_name: "",
      is_default_sort: false,
      type: "ACTION",
      sort_by: "",
      colum_prefix: "",
      class: "action-col",
      actions: [
        {
          title: t('Common.Delete'),
          ref_data: "id",
          type: "DELETE",
        },
      ],
    },
  ]);
  useEffect(() => { }, [props]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  const [action, setAction] = useState({});
  const [loader, setLoader] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  var watchvariable = watch(); 
  const checkActionCondition = (item: any, action: any) => {
    return true;
  };
  const deleteList = (isDelete: boolean) => {
    setshowDelete(false);
    if (isDelete) {
      props.sendData(1);
    }
  };


  const applyAction = (data: any) => {
    setshowDelete(false);
    if (data.type === "DELETE") {
      setDeleteId(data.ref.id);
    }
  };
  const setDeleteId = (id: any) => {
    setshowDelete(true);
    var obj = {
      id: id,
      status: "DELETED",
    };
    var qurey = new URLSearchParams(obj).toString();
    setAction("whitelist-ip/delete?" + qurey);
  };
  const applyChange = (data: any) => {
    props.sendData(data.currentPage);

  };
  const closeAddModal = () => {
    setShowAddModal(false);
    reset();
  };
  const handleError = () => {
    return false;
  };
  const saveNewIP = () => {
    WebService.addLoader("save3");
    if (searchParams.get("id") ?? "") {
      watchvariable.app_id = searchParams.get("id");
    } else {
      watchvariable.app_id = localStorage.getItem("app_id")
    }

    return WebService.postAPI({
      action: `whitelist-ip`,
      body: watchvariable,
    })
      .then((res: any) => {
        if (res)
          toast.success(res.message);
        WebService.removeLoader("save3");
        closeAddModal();

        props.sendData(1);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        WebService.removeLoader("save3");
        return error;
      });
  };
  return (
    <>
      <div className="table-wrap table-no-scroll position-relative mb-3 table-responsive">
        <WMTable
          action_condtion={(item: any, action: any) =>
            checkActionCondition(item, action)
          }
          loader={loader}
          totalCount={props.data.length}
          list={props.data}
          headers={headers}
          getAction={(data: any) => applyAction(data)}
          getData={(data: any) => applyChange(data)}
          no_record_message={t('Common.Whitelisted_Ip_Table_Err_Msg')}
        />
        <div className="text-center">
          <Button
            className="btn-wmx btn-lg"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <BiPlus size={16} className="me-1" /> {t('Common.Add_Ip_Address')}
          </Button>
        </div>
      </div>

      <DeleteModal
        action={action}
        show={showDelete}        
        onDelete={(deleted: boolean) => deleteList(deleted)}
      />
      <Offcanvas
        show={showAddModal}
        onHide={closeAddModal}
        placement="end"
        className="righSide-modal size-md"
      >
        <Form
          className="wmx-form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(saveNewIP, handleError)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t('Common.Whitelist_Ip_Address')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel label={t('Common.Name')} className="mb-3">
                    <Form.Control
                      {...register("name", { required: true })}
                      type="text"
                      id="Name"
                      //   value={ListName}
                      placeholder="Enter list name"
                      onChange={(event: any) => {
                        watchvariable.name = event.target.value;
                        setValue("name", watchvariable.name);
                      }}
                    />
                    {errors.name && (
                      <Label title={t('Common.Name_Err_Msg')} modeError={true} />
                    )}
                  </FloatingLabel>
                </div>
                <div className="mb-3">
                  <FloatingLabel label={t('Common.Ip_Address')} className="mb-3">
                    <Form.Control
                      {...register("ip", { required: true })}
                      type="text"
                      {...register("ip", {
                        required: t('Common.Enter_Ip_Err_Msg'),
                        pattern: {
                          value:
                            /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
                          message: "Please enter valid ip",
                        },
                      })}
                      placeholder="IP"
                      onChange={(event: any) => {
                        watchvariable.ip = event.target.value;
                        setValue("ip", watchvariable.ip);
                      }}
                    />
                    {errors.ip && (
                      <Label
                        title={errors.ip.message?.toString()}
                        modeError={true}
                      />
                    )}
                  </FloatingLabel>
                </div>
              </Col>
            </Row>
          </Offcanvas.Body>
          <div className="offcanvas-footer">
            <Button className="btn btn-wmx-gray" onClick={closeAddModal}>
              <MdClose size={18} className="icon" /> {t('Common.Close')}
            </Button>
            <Button className="btn  btn-wmx " id="save3" type="submit">
              {t('Common.Save')}
            </Button>
          </div>
        </Form>
      </Offcanvas>
    </>
  );
};
export default TabWhiteListedAPI;
