import { Routes, Route, RoutesProps } from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProtectedRoute from "./ProtectedRoute";
import Setting from "../components/Settings/Setting";
import AiGeneratedImages from "../components/AI-Creatives/Post/ai-generatedImage";
import { AccessDenied } from "../components/Common/AccessDenied/AccessDenied";
const Main = React.lazy(() => import("../components/Main"));
const AuthRedirect = React.lazy(
  () => import("../components/AuthRedirect/AuthRedirect")
);
const Profile = React.lazy(() => import("../components/Profile/Profile"));
const Brands = React.lazy(() => import("../components/Brands/Brands"));
const TeamMember = React.lazy(
  () => import("../components/TeamMember/TeamMember")
);


const AddBrand = React.lazy(
  () => import("../components/Brands/AddBrand/AddBrand")
);



const MySubscription = React.lazy(
  () => import("../components/Subscription/Subscription")
);

const CreateGoogleAdRule = React.lazy(
  () =>
    import(
      "../components/Ads-Automation/GoogleAds/CreateGoogleAdRule/CreateGoogleAdRule"
    )
);
const AdsRule = React.lazy(
  () => import("../components/Ads-Automation/AdsRule/AdsRule")
);
// const CreateAdsRules = React.lazy(
//   () => import("../components/Ads-Automation/CreateAdsRules/CreateAdsRules")
// );
const CreativeAiList = React.lazy(
  () => import("../components/AI-Creatives/List/List")
);
const CampaignTracker = React.lazy(
  () => import("../components/MarketingInsights/CampaignTracker/List/List")
);
const CreativeAiBase = React.lazy(
  () => import("../components/AI-Creatives/Post/CreativeAiBase")
);
const TopStrategy = React.lazy(
  () => import("../components/Top-strategy/Strategy")
);
const AIMarketer = React.lazy(
  () => import("../components/Ai-Marketer/AiMarketer")
);
const ContentScorecard = React.lazy(
  () => import("../pages/ContentScorecard/ContentScorecard")
);
const AmazonAds = React.lazy(
  () => import("../components/AmazonAds/AmazonAds")
);
const SearchTerm = React.lazy(
  () => import("../components/Reports/SearchTearm/SearchTerms")
);
const CampaignReport = React.lazy(
  () => import("../components/Reports/Campaign/Campaign")
);

const ShareofVoice = React.lazy(
  () => import("../pages/ShareofVoice/ShareofVoice")
);
const Keywords = React.lazy(
  () => import("../components/Keywords/Keywords")
);

const Airecommendation = React.lazy(
  () => import("../pages/Airecommendation/Airecommendation")
);
const Heatmaps = React.lazy(
  () => import("../components/Heatmaps/Heatmaps")
);

const ShareofVoiceSettings = React.lazy(
  () => import("../pages/ShareofVoiceSettings/ShareofVoiceSettings")
);

const AccountRedirect = React.lazy(
  () =>
    import("../components/MarketingInsights/AccountRedirect/AccountRedirect")
);
const Budget = React.lazy(
  () => import("../pages/Budget/Budget")
);

const CampaignDetails = React.lazy(
  () => import("../components/AmazonAds/Campaigns/CampaignDetails")
);
const AdsDetails = React.lazy(
  () => import("../components/AmazonAds/Campaigns/AdsDetails")
);
const ConnectedChannels = React.lazy(
  () =>
    import(
      "../components/MarketingInsights/CampaignsPerformance/ConnectedChannels/ConnectedChannels"
    )
);
const CreateFacebookAdRule = React.lazy(
  () =>
    import(
      "../components/Ads-Automation/FacebookAds/CreateFacebookAdRule/CreateFacebookAdRule"
    )
);
const TopCreativesList = React.lazy(
  () => import("../components/Top-Creatives/List/List")
);
const TopCreativesReport = React.lazy(
  () => import("../components/Top-Creatives/Report/Report")
);

const Navigation = () => {
  useEffect(() => {
  }, []);

  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };


  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route path="/auth/redirect" element={<AuthRedirect />} />
          <Route
            path="/setup"
            element={
              <Suspense fallback={<></>}>
                <AddBrand />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/profile"
              element={
                <Suspense fallback={<></>}>
                  <Profile />
                </Suspense>
              }
            />
            <Route
              path="/my-brand"
              element={
                <Suspense fallback={<></>}>
                  <Brands />
                </Suspense>
              }
            />
            <Route
              path="/team"
              element={
                <Suspense fallback={<></>}>
                  <TeamMember />
                </Suspense>
              }
            />
            <Route
              path="/my-subscription"
              element={
                <Suspense fallback={<></>}>
                  <MySubscription />
                </Suspense>
              }
            />
            <Route
              path="/shareofvoice"
              element={
                <Suspense fallback={<></>}>
                  <ShareofVoice />
                </Suspense>
              }
            />
            <Route
              path="/setting"
              element={
                <Suspense fallback={<></>}>
                  <Setting />
                </Suspense>
              }
            />
            <Route
              path="/heatmaps"
              element={
                <Suspense fallback={<></>}>
                  <Heatmaps />
                </Suspense>
              }
            />

            <Route
              path="/access-denied"
              element={
                <Suspense fallback={<></>}>
                  <AccessDenied />
                </Suspense>
              }
            />
            <Route
              path="campaign-tracker"
              element={
                <Suspense fallback={<></>}>
                  <CampaignTracker />
                </Suspense>
              }
            />
            <Route
              path="connected-channels"
              element={
                <Suspense fallback={<></>}>
                  <ConnectedChannels />
                </Suspense>
              }
            />
            <Route
              path="campaign-report"
              element={
                <Suspense fallback={<></>}>
                  <CampaignReport />
                </Suspense>
              }
            />
            <Route
              path="search-term"
              element={
                <Suspense fallback={<></>}>
                  <SearchTerm />
                </Suspense>
              }
            />
            <Route
              path="amazonads"
              element={
                <Suspense fallback={<></>}>
                  <AmazonAds />
                </Suspense>
              }
            />
            <Route
              path="campaign-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <CampaignDetails />
                </Suspense>
              }
            />
            <Route
              path="ad-group-details/:id"
              element={
                <Suspense fallback={<></>}>
                  <AdsDetails />
                </Suspense>
              }
            />
            <Route
              path="budget"
              element={
                <Suspense fallback={<></>}>
                  <Budget />
                </Suspense>
              }
            />
            <Route
              path="keywords"
              element={
                <Suspense fallback={<></>}>
                  <Keywords />
                </Suspense>
              }
            />
            <Route
              path="airecommendation"
              element={
                <Suspense fallback={<></>}>
                  <Airecommendation />
                </Suspense>
              }
            />
            <Route
              path="amazon/oauth/redirect"
              element={
                <Suspense fallback={<></>}>
                  <AccountRedirect linkType={"AMAZON"} />
                </Suspense>
              }
            />

            <Route
              path="/ads-automation"
              element={
                <Suspense fallback={<></>}>
                  <GoogleOAuthProvider clientId="843653690200-pdl90i2kpjtfe4o2mj4a75k7i20dec24.apps.googleusercontent.com">
                    <AdsRule />
                  </GoogleOAuthProvider>
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-google-ads-rules"
              element={
                <Suspense fallback={<></>}>
                  <CreateGoogleAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-google-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateGoogleAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/edit-google-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateGoogleAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-facebook-ads-rules"
              element={
                <Suspense fallback={<></>}>
                  <CreateFacebookAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/create-facebook-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateFacebookAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ads-automation/edit-facebook-ads-rules/:id"
              element={
                <Suspense fallback={<></>}>
                  <CreateFacebookAdRule />
                </Suspense>
              }
            />
            <Route
              path="/ai-creatives"
              element={
                <Suspense fallback={<></>}>
                  <CreativeAiList />
                </Suspense>
              }
            />
            <Route
              path="/contentscorecard"
              element={
                <Suspense fallback={<></>}>
                  <ContentScorecard />
                </Suspense>
              }
            />
            <Route
              path="/shareofvoicesettings"
              element={
                <Suspense fallback={<></>}>
                  <ShareofVoiceSettings />
                </Suspense>
              }
            />

            <Route
              path="/ai-creatives/add"
              element={
                <Suspense fallback={<></>}>
                  <CreativeAiBase />
                </Suspense>
              }
            />
            <Route
              path="/ai-generated-card/:id"
              element={
                <Suspense fallback={<></>}>
                  <AiGeneratedImages />
                </Suspense>
              }
            />
            <Route
              path="/ai-creatives/edit/:creative_post_id"
              element={
                <Suspense fallback={<></>}>
                  <CreativeAiBase />
                </Suspense>
              }
            />
            <Route
              path="/top-strategy"
              element={
                <Suspense fallback={<></>}>
                  <TopStrategy />
                </Suspense>
              }
            />
            <Route
              path="/ai-marketer"
              element={
                <Suspense fallback={<></>}>
                  <AIMarketer />
                </Suspense>
              }
            />
            <Route
              path="/top-creatives"
              element={
                <Suspense fallback={<></>}>
                  <TopCreativesList />
                </Suspense>
              }
            />
            <Route
              path="/top-creatives-report/:id"
              element={
                <Suspense fallback={<></>}>
                  <TopCreativesReport />
                </Suspense>
              }
            />
          </Route>

        </Routes>
      </div>
    </>
  );
};

export default Navigation;
