import React from "react";
import accessDenied from "../../../assets/images/access-denied.png";
export const AccessDenied = (props: any) => {
  return (
    <div className="app-body kpi-dahboard-page">
      <div className="table-wrap position-relative">
        <div className="no-data-flound " style={{height:"89vh"}} >
          <div className="text-center">
            <img alt="" src={accessDenied} width={110} className="mb-3" />
            <h3 className="text-secondary font-14"><strong>Access Denied</strong></h3>
            <p className="text-secondary font-12">You don't have permission to visit this page.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
