

const products = {
    "whatsapp": "whatsapp-commerce",
    "egrowth": "egrowth",
    "support": "help-desk",
    "promarketer": "promarketer-ai",
    "automation": "marketing-automation",
    "social": "social-campaign",
}

export default products;