import { useState, useEffect } from 'react';
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';
import { Flip } from 'react-toastify';
import './App.scss';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import { getMsg } from './components/firebase';
const App = () => {
  return (
    <>
      <div className="App">
        <Navigation />
        <ToastContainer theme="colored" transition={Flip} />
      </div>
    </>
  );
};
export default App;
