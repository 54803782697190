import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = "https://staging-webmaxyai.webmaxy.co";

interface PropData {
  action: string;
  body?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  _file?: File;
  key?: string;
}
const WebServiceNode = {
  postAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        xapikey: localStorage.getItem("p_user_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  putAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        xapikey: localStorage.getItem("p_user_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .put(`${BASE_URL}${props.action}`, props.body, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  getAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .get(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  errorHandler: function (error: any) {
    if (error?.response) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      // localStorage.clear();
      // window.location.href = "/login";
    } else if (error.status === 500) {
      errorMessage =
        (error &&
          error.data &&
          error.data.ErrorDetails &&
          error.data.ErrorDetails.message) ||
        "An unkown exception has occur. Please Contact administrator";
    } else {
      errorMessage = error.data.message;
    }
    toast.error(errorMessage);
    return errorMessage;
  },

};

export default WebServiceNode;
