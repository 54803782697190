
const StepWizard = (props:any) => {
    return (
        <>

            <div className={props.stepItemClassName}>
                <div className="d-flex"> <span className="step-no">{props.stepNo}</span> <span>{props.stepName}</span> </div>
            </div>
             

        </>
    )
}
export default StepWizard;