import React, { useEffect, useState } from "react";
import "./WebMaxyPagination.scss";
import { Pagination } from 'react-bootstrap';
import { HiOutlineArrowRight, HiOutlineArrowLeft } from "react-icons/hi";
import { useTranslation } from "react-i18next";
// import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";

interface PaginationProps {
  totalItems: number;
  itemsCountPerPage?: number;
  className?: string;
  changePage: any;
  changeCurrentPage?: any;
  showLoader?: boolean;
}

const WebMaxyPagination = (props: PaginationProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [pages, setPages] = useState<Number[]>([]);
  const [tempStatus, setTempStatus] = useState(false);


  useEffect(() => {
    if (!isNaN(props.changeCurrentPage)) {
      setCurrentPage(props.changeCurrentPage)
    }
  }, [props.changeCurrentPage])




  useEffect(() => {
    var totalItems: number = props.totalItems ? props.totalItems : Number(10);
    var itemsCountPerPage: number = props.itemsCountPerPage
      ? props.itemsCountPerPage
      : Number(10);
    const totalPage = Math.ceil(totalItems / itemsCountPerPage);
    setLastPage(totalPage);
    var temp = [];
    let startPage, endPage;
    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      temp.push(i);
    }
    if (temp.length > 0) {
      setPages(temp);
    }
    setTimeout(() => {
      changePageEvent(currentPage);
    }, 100);
  }, [currentPage, props.totalItems]);

  const changepage = (page: number) => {
    if (!isNaN(page)) {
      setCurrentPage(page);
      props.changePage(page);
    }

  };

  const nextchangepage = () => {
    setCurrentPage(currentPage === lastPage ? lastPage : currentPage + 1);
    if (currentPage === lastPage) {
      props.changePage(currentPage);
    } else {
      props.changePage(currentPage + 1);
    }

    return setTempStatus(tempStatus => !tempStatus);
  };

  const setFirstPage = () => {
    setCurrentPage(1)
    props.changePage(1)
  }

  const setLasttPage = () => {
    setCurrentPage(lastPage)
    props.changePage(lastPage)
  }

  const previouschangepage = () => {
    setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
    if (currentPage > 1) {
      //   props.changePage(currentPage);
      // } else {
      props.changePage(currentPage - 1);
    }
    return setTempStatus(tempStatus => !tempStatus);
  };

  function changePageEvent(page: any) {
    var clasEle = document.getElementsByClassName("currentPage");
    if (clasEle.length > 0) {
      for (let index = 0; index < clasEle.length; index++) {
        const element = clasEle[index];
        element.classList.remove("currentPage")
      }
    }
    setTimeout(() => {
      document.getElementById("page_" + page)?.classList.add("currentPage");
    }, 100);
  }



  useEffect(() => {
    if (!props.showLoader) {
      setTimeout(() => {
        document.getElementById("page_" + currentPage)?.classList.add("currentPage");
      }, 10);
    }
  }, [props.showLoader])

  return (
    <>
      {props.totalItems > 10 ? <Pagination>
        <div className="d-flex gap-2">
          {
            currentPage !== 1 ?
              <Pagination.First className='pagination-btn' onClick={() => setFirstPage()}>{t('Common.First')}</Pagination.First >
              : ""
          }
          <Pagination.Prev className='pagination-btn' onClick={() => previouschangepage()}> <HiOutlineArrowLeft size={18} /> {t('Common.Prev')}</Pagination.Prev>
        </div>
        <div className='counts'>
          {pages.map((page: any, index: number) => {
            return (
              <div key={"Pagination_" + index} ><Pagination.Item disabled={props.showLoader} onClick={() => changepage(page)} id={"page_" + page}>{page}</Pagination.Item></div>
            );
          })}
        </div>
        <div className="d-flex gap-2">
          <Pagination.Next className='pagination-btn' onClick={() => nextchangepage()}>{t('Common.Next')} <HiOutlineArrowRight size={18} onClick={() => nextchangepage()} /></Pagination.Next>
          {
            currentPage !== lastPage ?
              <Pagination.Last className='pagination-btn' onClick={() => setLasttPage()}>{t('Common.Last')}</Pagination.Last >
              : ""
          }

        </div>
      </Pagination>
        : ""}

    </>
  );
};

export default WebMaxyPagination;
