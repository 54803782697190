import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form, FloatingLabel } from "react-bootstrap";
import { BsSave } from "react-icons/bs";
import WoocommerceLogo from "../../../assets/images/woocommerce-logo.svg";
import { useForm } from "react-hook-form";
import { Label } from "../../Common/Label/Label";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Password from "../../Common/Password/Password";

const TabWoocommers = (props: any) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  var watchVariable = watch();
  // const videoRef = useRef<any>();
  const [stop, setStop] = useState(false);
  useEffect(() => {
    // if (props.video === stop) {
    //   videoRef.current.pause();
    // } else {
    //   videoRef.current.play();
    // }
    watchVariable.consumer_key = props.data.consumer_key;
    watchVariable.consumer_value = props.data.consumer_value;
    watchVariable.stop = props.data.stop;
    setValue("consumer_key", watchVariable.consumer_key);
    setValue("consumer_value", watchVariable.consumer_value);
  }, [props]);
  const handleError = () => {
    return false;
  };
  const handleVideo = () => {
    //setStop(!stop);


  };
  const UpdateWooCommerceKeys = () => {
    WebService.addLoader("save1");
    return WebService.putAPI({
      action: `brand-setting`,
      body: watchVariable,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("save1");
      })
      .catch((error) => {
        WebService.removeLoader("save1");
        return error;
      });
  };
  const updateData = (value: any, type: any) => {
    if (type === "consumer_key") {
      watchVariable.consumer_key = value;
      setValue("consumer_key", watchVariable.consumer_key);
    } else if (type === "consumer_value") {
      watchVariable.consumer_value = value;
      setValue("consumer_value", watchVariable.consumer_value);
    }
  };
  return (
    <>
      <Card className="border-0">
        <Form
          className="wmx-form-style"
          name="Verify1"
          id="Verify1"
          onSubmit={handleSubmit(UpdateWooCommerceKeys, handleError)}
        >
          <Card.Body className="px-4">
            <div className="text-center mb-3">
              {" "}
              <img alt="" src={WoocommerceLogo} width={180} className="mx-auto" />
            </div>
            <Form className="wmx-form-style">
              <Row className=" justify-content-center">
                <Col lg={12}>
                  <div className="mb-3">
                    <FloatingLabel label={t('Brands.Consumer_Key')}>
                      <Form.Control
                        {...register("consumer_key", { required: true })}
                        type="text"
                        id="consumer_key"
                        placeholder="Consumer key"
                        value={watchVariable.consumer_key}
                        onChange={(e) => {
                          updateData(e.target.value, "consumer_key");
                        }}
                      />
                      {errors.consumer_key && (
                        <Label
                          title="Please enter consumer key"
                          modeError={true}
                        />
                      )}
                    </FloatingLabel>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <Password requiredMsg={{ ...register("consumer_value", { required: true }) }}
                      id="brand"
                      name=""
                      label={t('Brands.Consumer_Secret')}
                      placeholder="Consumer secret"
                      method={(e: any) => {
                        updateData(e.target.value, "consumer_value");
                      }}
                      onError={
                        errors.consumer_value && (
                          <Label
                            title="Please enter consumer secret"
                            modeError={true}
                          />
                        )
                      }
                    />
                  </div>
                </Col>
                <Col lg={10}>
                  {/* <iframe
                    width="100%"
                    height="220px"
                    src="https://cdn.webmaxy.co/woo.mp4"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe> */}
                  {/* <video width="100%" ref={videoRef} height="220px" loop controls id="video">
                    <source src="https://cdn.webmaxy.co/woo.mp4" type="video/mp4" />
                  </video> */}
                </Col>
              </Row>
            </Form>
          </Card.Body>
          <Card.Footer className="bg-white text-center rounded-bottom-3 border-light py-3">
            <Button className="btn-wmx btn-lg" type="submit" id="save1">
              <BsSave size={16} className="me-1" /> {t('Common.Save')}
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </>
  );
};
export default TabWoocommers;
